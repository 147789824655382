<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                
                <div class="card elevation-5">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <!-- <div class="col-lg-2 col-6">
                                <v-select v-model="regionModel" :items="regionLists" default="" item-value="region" item-text="region" label="Region" :disabled="disabled_region == true" @change="(event) => updateOfficeDropdown(event)" clearable dense prepend-icon="mdi-city"></v-select>
                            </div> -->
                            <div class="col-lg-3 col-6">
                                <v-select v-model="officeModel" :items="officeLists" default="" item-value="office_id" item-text="office" label="Branch" :disabled="disabled_office == true" @change="(event) => updateSalesmanDropdown(event)" clearable dense prepend-icon="mdi-home-variant"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-select v-model="salesmanModel" :items="salesmanLists" default="" item-value="SalesId" item-text="NamaSales" label="Salesman" :disabled="disabled_salesman == true" clearable dense prepend-icon="mdi-account"></v-select>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog" v-model="dateStartModal" :return-value.sync="dateStartModel" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateStartModel" label="Start Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateStartModel" :show-current="true" @click:date="$refs.dialog.save(dateStartModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateStartModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 col-6">
                                <v-dialog ref="dialog2" v-model="dateEndModal" :return-value.sync="dateEndModel" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateEndModel" label="End Date" dense readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateEndModel" :show-current="true" @click:date="$refs.dialog2.save(dateEndModel)" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="dateEndModal = false">Cancel</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-2 text-left">
                                <v-btn class="mt-3" color="primary" elevation="2" small rounded @click="getData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="col-lg-12 cardResult">
                <div class="card elevation-5">
                    <div class="card-body">
                        <h5 class="card-title text-primary">Result</h5>
                            <v-data-table :headers="headers" :items="itemLists" class="elevation-1" page-count="5" :search="searchItem" fixed-header height="75vh" :loading="loadingDatatable" dense>
                                <template v-slot:top>
                                    <v-toolbar flat color="white">
                                        <div class="d-flex w-25">
                                            <v-text-field v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                        </div>
                                    </v-toolbar>
                                </template>
                                <template v-slot:[`item.no`]="{ index }">
                                    {{ index + 1 }}
                                </template>
                                <template v-slot:[`item.showDetails`]="{ item }">
                                    <!-- <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-chip class="ma-1" color="blue" small elevation="2" @click="showItemDetails(item)" v-on="on" dark><v-icon dark>mdi-eye</v-icon></v-chip>
                                            <v-chip class="ma-1" color="blue" small elevation="2" @click="PrintSP(item)" v-on="on" dark><v-icon dark>mdi-printer</v-icon></v-chip>
                                            <v-chip v-if="(item.user_id == $store.state.user.username)" class="ma-1" color="red" small elevation="2" @click="deleteHeader(item)" v-on="on" dark><v-icon dark>mdi-delete</v-icon></v-chip>
                                        </template>
                                        <span>Show Details</span>
                                    </v-tooltip> -->
                                    <v-menu offset-y>
                                        <template v-slot:activator="{ on }">
                                        <v-btn icon v-on="on">
                                            <v-icon>mdi-dots-vertical</v-icon>
                                        </v-btn>
                                        </template>
                                        <v-list>
                                            <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="showItemDetails(item)">Details</v-list-item-title>
                                            </v-list-item>
                                            <!-- <v-list-item link>
                                                <v-list-item-title class="ma-2" @click="PrintSP(item)">Print</v-list-item-title>
                                            </v-list-item>
                                            <v-list-item v-if="item.stat != 'S' || item.stat != 'X'" link>
                                                <v-list-item-title class="ma-2" @click="showDeleteHeader(item)">Cancel</v-list-item-title>
                                            </v-list-item> -->
                                        </v-list>
                                    </v-menu>
                                    
                                </template>
                                <template v-slot:[`item.customerorderno`]="{ item }">
                                    <div v-if="item.customerorderno != ''">
                                        <v-chip class="ma-1" color="green" small dark>{{ item.customerorderno }}</v-chip>
                                    </div>
                                </template>
                                <template v-slot:[`item.image`]="{ item }">
                                    <div v-if="item.image != ''">
                                        <v-chip class="ma-1" color="blue" @click="showImageModal(item.image)" small dark><v-icon>mdi-file-image</v-icon></v-chip>
                                    </div>
                                </template>
                                <template v-slot:[`item.stat`]="{ item }">
                                    <td v-if="item.stat == 'O'">
                                        <v-chip class="ma-1" color="warning" small dark>OPEN</v-chip>
                                    </td>
                                    <td v-else-if="item.stat == 'P'">  
                                        <v-chip class="ma-1" color="green" small dark>ADMIN CHECK</v-chip>
                                    </td>
                                    <td v-else-if="item.stat == 'R'">
                                        <v-chip class="ma-1" color="warning" small dark>NEED CONFIRM</v-chip>
                                    </td>
                                    <td v-else-if="item.stat == 'S'">
                                        <v-chip class="ma-1" color="green" small dark>CONFIRMED</v-chip>
                                    </td>
                                    <td v-else-if="item.stat == 'X'">
                                        <v-chip class="ma-1" color="red" small dark>CANCELLED</v-chip>
                                    </td>
                                    <td v-else>
                                        <v-chip class="ma-1" color="warning" small>UNKNOWN</v-chip>
                                    </td>
                                </template>
                            </v-data-table>
                    </div>
                </div>

            </div>

        </div>

        <!-- <div class="modal fade modal-xl" id="detailModal" tabindex="-1" aria-labelledby="detailModalLabel" aria-hidden="true">
            <div class="modal-dialog" style="max-width: 80%;">
                <div class="modal-content bgCustom3">
                <div class="modal-header">
                    <div class="row">
                        <div class="col-6 col-lg-6 text-left">
                            <div v-if="orderStat == 'O'">
                                <v-btn class="mr-1" color="green" elevation="2" @click="addItem()" dark>ADD ITEMS<v-icon dark>mdi-cart-plus</v-icon></v-btn>
                            </div>
                        </div>
                        <div class="col-6 col-lg-6 text-right">
                                <h5 class="card-title ma-3 text-primary">Total Weight : {{ totalWgtOrder ? totalWgtOrder : 0 }} Kg</h5>
                        </div>
                    </div>
                    
                </div>
                <div class="modal-body">
                    <div id="divPrint">
                        <v-data-table :headers="headersDetails" :items="itemDetailLists" class="table" :loading="loadingDatatableDetail" dense>
                            <template v-slot:[`item.no`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.set_price`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.set_price / 1.11) }}
                            </template>
                            <template v-slot:[`item.total_price`]="{ item }">
                                {{ $store.getters.convertToCurrencyUs(item.total_price / 1.11) }}
                            </template>
                            <template v-slot:[`item.stat`]="{ item }">
                                <div v-if="item.stat == 'O'">
                                    <v-chip class="ma-1" color="warning" dense small dark>OPEN</v-chip>
                                </div>
                                <div v-else-if="item.stat == 'P'">  
                                    <v-chip class="ma-1" color="green" dense small dark>ADMIN CHECK</v-chip>
                                </div>
                                <div v-else-if="item.stat == 'R'">
                                    <v-chip class="ma-1" color="warning" dense small dark>NEED CONFIRM</v-chip>
                                </div>
                                <div v-else-if="item.stat == 'S'">
                                    <v-chip class="ma-1" color="green" dense small dark>CONFIRMED</v-chip>
                                </div>
                                <div v-else-if="item.stat == 'X'">
                                    <v-chip class="ma-1" color="red" dense small dark>CANCELLED</v-chip>
                                </div>
                                <div v-else>
                                    <v-chip class="ma-1" color="warning" dense small>UNKNOWN</v-chip>
                                </div>
                            </template>
                            <template v-slot:[`item.delete`]="{ item }">
                                <div v-if="(item.created_by == $store.state.user.username)">
                                    <div v-if="item.customerorderno == '' && item.stat != 'X' && item.stat != 'S' ">
                                        <v-tooltip top>
                                            <template v-slot:activator="{ on }">
                                                <v-chip class="mr-3" color="red" dense small elevation="2" @click="showDeleteItem(item)" v-on="on" dark><v-icon dark>mdi-close-box</v-icon></v-chip>
                                            </template>
                                            <span>Cancel</span>
                                        </v-tooltip>
                                    </div>
                                </div>
                            </template>

                            <template v-slot:footer>
                                <hr />
                                <div class="text-right mr-6"><span class="font-weight-bold">Total : {{ subTotal ? $store.getters.convertToCurrencyUs(subTotal / 1.11) : 0 }}</span></div>
                                <div class="text-right mr-6"><span class="font-weight-bold">Tax (11%) : {{ tax ? $store.getters.convertToCurrencyUs((subTotal * 0.11) / 1.11) : 0 }}</span></div>
                                <div class="text-right mr-6"><span class="font-weight-bold">Sub Total : {{ total ? $store.getters.convertToCurrencyUs((subTotal / 1.11) + (subTotal * 0.11) / 1.11 ) : 0 }}</span></div>
                                <div class="text-right mr-6"><span class="font-weight-bold"><i>" {{ totalTerbilang ? totalTerbilang : '' }} Rupiah"</i></span></div>
                                <hr />
                                <div v-if="orderStat == 'O'" class="text-right mt-3 mb-3 mr-6"><v-chip class="ma-1" link small dark color="primary" @click="sendToAdmin()">SEND TO ADMIN</v-chip></div>
                                <div v-if="orderStat == 'R'" class="text-right mt-3 mb-3 mr-6"><v-chip class="ma-1" link small dark color="primary" @click="confirmOrder()">CONFIRM</v-chip></div>
                            </template>
                        </v-data-table>
                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div> -->

        <v-dialog v-model="dialogDetailItem" max-width="80%" class="ma-2" persistent :retain-focus="false">
            <div class="card">
                <!-- <div class="card-header">
                    <div class="row">
                        <div class="col-6 col-lg-6 text-left">
                            <div v-if="createdBy == $store.state.user.username ">
                                <v-btn v-if="orderStat == 'O' || orderStat == 'P'" class="mr-1" color="green" elevation="2" @click="addItem()" dark>ADD ITEMS<v-icon dark>mdi-cart-plus</v-icon></v-btn>
                            </div>
                        </div>
                        <div class="col-6 col-lg-6 text-right">
                                <h5 class="card-title ma-3 text-primary">Total Weight : {{ totalWgtOrder ? totalWgtOrder : 0 }} Kg</h5>
                        </div>
                    </div>
                </div> -->
                <div class="card-body">
                    <div id="divPrint">
                        <v-data-table :headers="headersDetails" :items="itemDetailLists" class="elevation-1" :loading="loadingDatatableDetail" dense>
                            <!-- <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template> -->
                            <template v-slot:[`item.Panjang`]="{ item }">
                                {{ parseFloat(item.Panjang).toFixed(2) }}
                            </template>
                            <template v-slot:[`item.Lembar`]="{ item }">
                                {{ parseInt(item.Panjang) }}
                            </template>
                            <template v-slot:[`item.HargaSatuan`]="{ item }">
                                Rp. {{ parseInt(item.HargaSatuan) }}
                            </template>
                            <template v-slot:[`item.Total`]="{ item }">
                                Rp. {{ parseInt(item.Total) }}
                            </template>
                        </v-data-table>
                    </div>
                </div>
                <div class="card-footer text-muted text-right">
                    <v-chip class="ma-1" color="red" dense small dark @click="dialogDetailItem = !dialogDetailItem">CLOSE</v-chip>
                </div>
            </div>
        </v-dialog>

        <div class="modal fade modal-md" id="imgModal" tabindex="-1" aria-labelledby="imgModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content bgCustom3">
                    <div class="modal-body">
                        <v-img :src=imageUrl ></v-img>
                    </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
                </div>
                </div>
            </div>
        </div>

        <v-dialog
            v-model="dialog_delete"
            max-width="600px"
            persistent
            :retain-focus="false"
        >
            <v-card style="overflow: hidden;">
                <v-card-title class="p-1">                        
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mx-2"
                        fab
                        x-small
                        color="#e83e8c;"
                        @click="[dialog_delete = false, order = null]"
                    >
                        <v-icon dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <h6 class="text-center">
                                    {{ order ? order.book_id : '' }}
                                </h6>
                                <p class="text-center">
                                    Are you sure Delete this Order ? <br>
                                    If yes, please fill this form below
                                </p>
                            </v-col>
                            <v-col cols="12" class="mt-3">
                                <h6 class="blue-lcd mb-1">Reason <span class="red--text"><strong>* </strong></span></h6>   
                                <v-autocomplete
                                    dense
                                    solo
                                    clearable
                                    v-model="reason_id"
                                    :items="reasons"
                                    item-value="str1"
                                    item-text="str2"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>  
                            </v-col>
                            <v-col cols="12">
                                <h6 class="blue-lcd mb-1">Remark</h6>  
                                <v-textarea solo v-model="remark" rows="3" clearable></v-textarea> 
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions class="text-center p-3">
                    <v-row justify="center">
                        <v-col cols="12" xs="12" sm="3" md="3">
                            <v-btn block class="rounded-l p-3" @click="[dialog_delete = false, order = null]">Close</v-btn>
                        </v-col>
                        <v-col cols="12" xs="12" sm="3" md="3"> 
                            <v-btn block class="rounded-l text-white p-3" color="#003871" @click="deleteHeader()">Submit</v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogAddItem" max-width="80%" class="ma-2" persistent>

            <div class="card">
                <div class="card-header">
                    <h5>Add Items</h5>
                </div>
                <div class="card-body">
                    <div class="row">

                        <div class="col-lg-2">
                            <v-select v-model="brandModel" :items="brandLists" item-value="value" item-text="text" label="BRAND" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-select v-model="categoryModel" :items="categoryLists" item-value="category_id" item-text="cat_desc" label="CATEGORY" @change="populateMachTypeByCategory()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-select v-model="machTypeModel" :items="machTypeLists" item-value="mach_type" :item-text="item => item.mach_type +' - '+ item.descr" label="MACH TYPE" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-select v-model="thicknessModel" :items="thicknessLists" item-value="Tebal" item-text="Tebal" label="THICKNESS" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-select v-model="widthModel" :items="widthLists" item-value="matrl_width" item-text="matrl_width" label="WIDTH" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-text-field v-model="lengthModel" label="LENGTH" @keypress="isNumber2($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                        </div>
                        <div class="col-lg-2">
                            <v-select v-model="colorModel" :items="colorLists" item-value="KodeWarna" item-text="NamaWarna" label="COLOR" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-select v-model="materialModel" :items="materialLists" item-value="Jenis" item-text="Jenis" label="MATERIAL" @change="populateProdCode()" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-select v-model="addIDModel" :items="addIDLists" item-value="add_id" item-text="descr" label="ADD ID" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-select v-model="torIDModel" :items="torIDLists" item-value="tor_id" item-text="descr" label="TOR ID" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-text-field v-model="quantityModel" label="QUANTITY" @keypress="isNumber($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                        </div>
                        <div class="col-lg-2">
                            <v-text-field v-model="priceModel" label="PRICE" @keypress="isNumber($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                        </div>
                        <div class="col-lg-6">
                            <v-select v-model="prodIDModel" :items="prodIDLists" item-value="BarangJadiId" :item-text="item => item.BarangJadiId +' - '+ item.NamaBarang" :label="prodCodeText" :loading="loadingProdCode" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-6">
                            <v-radio-group v-model="priceModeModel" row>
                                <v-radio label="price By Length" value="M"></v-radio>
                                <v-radio label="price By Quantity" value="U"></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="col-lg-12">
                            <v-textarea v-model="remarkItemModel" label="REMARK" rows="2" clearable dense prepend-icon="mdi-note-outline"></v-textarea>
                        </div>
                        <div class="col-lg-12 text-right">
                            <v-btn class="mr-4" color="primary" elevation="2" large rounded @click="storeData()">Add Items to Cart<v-icon dark>mdi-cart-plus</v-icon></v-btn>
                        </div>

                    </div>

                </div>
                <div class="card-footer text-muted text-right">
                    <v-chip class="ma-1" color="red" dense small dark @click="dialogAddItem = !dialogAddItem">CLOSE</v-chip>
                </div>
            </div>

        </v-dialog>

        <v-dialog v-model="dialogEditItem" max-width="60%" class="ma-2" persistent>
        
            <div class="card">
                <div class="card-header">
                    <h5>Edit Item</h5>
                </div>
                <div class="card-body">
                    <div class="row">

                        <div class="col-lg-4">
                            <v-select v-model="addIDModel" :items="addIDLists" item-value="add_id" item-text="descr" label="ADD ID" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-4">
                            <v-select v-model="torIDModel" :items="torIDLists" item-value="tor_id" item-text="descr" label="TOR ID" clearable dense prepend-icon="mdi-google-nearby"></v-select>
                        </div>
                        <div class="col-lg-2">
                            <v-text-field v-model="editLengthModel" label="LENGTH" @keypress="isNumber2($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                        </div>
                        <div class="col-lg-2">
                            <v-text-field v-model="editQtyModel" label="QTY." @keypress="isNumber2($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                        </div>
                        <div class="col-lg-4">
                            <v-text-field v-model="editPriceModel" label="PRICE" @keypress="isNumber2($event)" clearable dense prepend-icon="mdi-google-nearby"></v-text-field>
                        </div>
                        <div class="col-lg-4">
                            <v-radio-group v-model="priceEditModeModel" dense row>
                                <v-radio label="price By Length" value="M"></v-radio>
                                <v-radio label="price By Quantity" value="U"></v-radio>
                            </v-radio-group>
                        </div>
                        <div class="col-lg-4">
                            <v-btn class="mt-3 ml-3" color="primary" elevation="2" small rounded @click="submitEditItem()">SUBMIT</v-btn>
                        </div>

                    </div>

                </div>
                <div class="card-footer text-muted text-right">
                    <v-chip class="ma-1" color="red" dense small dark @click="dialogEditItem = !dialogEditItem">CLOSE</v-chip>
                </div>
            </div>

        </v-dialog>

        <div class="modal fade modal-md" id="detailDeleteItem" tabindex="-1" aria-labelledby="detailDeleteItemLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content bgCustom3">
                    <div class="modal-body">
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <h6 class="text-center">
                                        {{ order_item ? order_item.book_id : '' }}
                                    </h6>
                                    <p class="text-center">
                                        Are you sure Delete this Order Item ? <br>
                                        If yes, please fill this form below
                                    </p>
                                </v-col>
                                <v-col cols="12" class="mt-3">
                                    <h6 class="blue-lcd mb-1">Reason <span class="red--text"><strong>* </strong></span></h6>   
                                    <v-autocomplete
                                        dense
                                        solo
                                        clearable
                                        v-model="reason_id"
                                        :items="reasons"
                                        item-value="str1"
                                        item-text="str2"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                    ></v-autocomplete>  
                                </v-col>
                                <v-col cols="12">
                                    <h6 class="blue-lcd mb-1">Remark</h6>  
                                    <v-textarea solo v-model="remark" rows="3"></v-textarea> 
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                    <div class="modal-footer">
                        <v-row justify="center">
                            <v-col cols="12" xs="12" sm="3" md="3">
                                <button type="button" class="btn btn-danger text-white" data-bs-dismiss="modal">Close</button>
                            </v-col>
                            <v-col cols="12" xs="12" sm="3" md="3"> 
                                <v-btn block class="rounded-l text-white p-3" color="#003871" @click="deleteItem()">Submit</v-btn>
                            </v-col>
                        </v-row>
                        
                    </div>
                </div>
                
            </div>
        </div>

    </div>
    
</template>

<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'E-Kencana',
                disabled: false,
                href: '/admin/kmb',
                },
                {
                text: 'Customer Order',
                disabled: false,
                href: '#',
                },
                {
                text: 'Order List',
                disabled: true,
                href: '#',
                },
            ],
            regionModel: '',
            regionLists: [],
            disabled_region: false,
            officeModel: '',
            officeLists: [],
            disabled_office: false,
            salesmanModel: '',
            salesmanLists: [],
            disabled_salesman: false,
            dateStartModel: '',
            dateStartModal: false,
            dateEndModel: '',
            dateEndModal: false,
            itemLists: [],
            headers: [
                { text: 'ORDER DATE', value: 'TglPesan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'OFFICE', value: 'office', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'ORDER ID', value: 'CustomerOrderNo', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUST. ID', value: 'CustomerId', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'CUST. NAME', value: 'NamaCustomer', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'RECEIVER', value: 'Penerima', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'SHIP. TO', value: 'DikirimKe', align: 'left', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'remark', align: 'left', class: 'primary--text blue lighten-5' },
                { text: '', value: 'showDetails', align: 'center', class: 'primary--text blue lighten-5' }
            ],
            searchItem: '',
            loadingDatatable: false,
            detailModalTitle: '',
            itemDetailLists: [],
            headersDetails: [
                { text: 'ITEM NO.', value: 'item_num', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROD. CODE', value: 'BarangJadiId', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PROD. DESC.', value: 'description', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'LENGTH', value: 'Panjang', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'QTY.', value: 'Lembar', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'PRICE', value: 'HargaSatuan', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'TOTAL', value: 'Total', align: 'right', class: 'primary--text blue lighten-5' },
                { text: 'REMARK', value: 'remark', align: 'right', class: 'primary--text blue lighten-5' },
            ],
            loadingDatatableDetail: false,
            imageUrl: '',
            subTotal : '',
            total : '',
            tax : '',
            totalTerbilang: '',
            addItemBookIdModel: '',
            brandModel: '',
            brandLists:[
                {
                    value: 'KENCANA',
                    text: 'KENCANA'
                },
                {
                    value: 'ARYA',
                    text: 'ARYA'
                },
                {
                    value: 'SEMANGGI',
                    text: 'SEMANGGI'
                },
            ],
            categoryModel: '',
            categoryLists: [],
            machTypeModel: '',
            machTypeLists: [],
            thicknessModel: '',
            thicknessLists: [],
            widthModel: '',
            widthLists: [],
            lengthModel: '',
            colorModel: '',
            colorLists: [],
            materialModel: '',
            materialLists: [],
            addIDModel: '',
            addIDLists: [],
            torIDModel: '',
            torIDLists: [],
            quantityModel: '',
            priceModel: '',
            priceModeModel: 'M',
            prodIDModel: '',
            prodIDLists: [],
            remarkItemModel: '',
            prodCodeText: 'PRODUCT CODE',
            loadingProdCode: false,
            userGroup: null,
            appl_id: 'SALES ORDER',
            orderStat: null,
            activeOrderId: '',
            totalWgtOrder: 0,
            reason_id: '',
            reasons: [],
            remark: '',
            dialog_delete: false,
            dialog_delete_item: false,
            order_item: '',
            order: '',
            dialogDetailItem: false,
            createdBy: '',
            dialogAddItem: false,
            dialogEditItem: false,
            editLengthModel: '',
            editQtyModel: '',
            editPriceModel: '',
            editBookId: '',
            editItemNum: '',
            priceEditModeModel: 'M',
            addIDModel: '',
            addIDLists: [],
            torIDModel: '',
            torIDLists: []
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.initialize()
        await this.getApplConstant()
        this.$store.dispatch('setOverlay', false)
        
    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)

            $('.cardResult').hide()

            //this.dateStartModel = this.currentDate()
            //this.dateEndModel = this.currentDate()

            await axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                // if(res.data.region.length == 1){
                //     this.regionModel = res.data.region[0].region
                //     this.regionLists = res.data.region
                //     this.disabled_region = true
                // } else {
                //     this.regionLists = res.data.region
                //     this.disabled_region = false
                // }
                if(res.data.office.length == 1){
                    this.officeModel = res.data.office[0].office_id
                    this.officeLists = res.data.office
                    this.disabled_office = true
                } else {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                }
                if(res.data.sales.length == 1){
                    this.salesmanModel = res.data.sales[0].SalesId
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = true
                } else {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                }

                this.addIDLists = res.data.add_id
                this.torIDLists = res.data.tor_id

                this.$store.dispatch('setOverlay', false)
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async getApplConstant(){
            const respData = await backendApi.fetchCore(`/v2/master/kmb/appl_constan?key_id=RCANCEL`, '', false, false, false)

            if (respData.status === 200) {
                this.reasons = respData.data
            }
        },

        async getData(){

            document.querySelector(".cardResult").style.display = "block";
            this.itemLists = []
            this.loadingDatatable = true
            this.$store.dispatch('setOverlay', true)

            if (this.dateStartModel) {
                this.dateStartFormatted = new Date(this.dateStartModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateStartFormatted = ''
            }

            if (this.dateEndModel) {
                this.dateEndFormatted = new Date(this.dateEndModel).toISOString().substr(0, 10).replace(/-/g,"")
            } else  {
                this.dateEndFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kri/OrderList/getData`, { 

                    // region: this.regionModel ? this.regionModel : '',
                    office: this.officeModel ? this.officeModel : '',
                    sales: this.salesmanModel ? this.salesmanModel : '',
                    dateFrom: this.dateStartFormatted,
                    dateTo: this.dateEndFormatted,

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                if(res.data.result.length != 0){
                    document.querySelector(".cardResult").style.display = "block";
                    this.itemLists = res.data.result
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                } else {
                    this.$store.dispatch('setOverlay', false)
                    this.loadingDatatable = false
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'warning',
                    })
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async showItemDetails(item){

            // $('#detailModal').modal('show')
            this.dialogDetailItem = true
            this.itemDetailLists = []
            this.loadingDatatableDetail = true
            // this.addItemBookIdModel = item.book_id
            // this.activeOrderId = item.book_id
            // this.orderStat = item.stat
            // this.createdBy = item.created_by
            // this.totalWgtOrder = 0

            this.getItemDetails(item.CustomerOrderNo)

            // axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList/getDataDetails?book_id=${item.book_id}`, { 
            //     headers: { 
            //         'Accept': 'application/json',
            //         'Content-Type': 'application/json',
            //         'Access-Control-Allow-Origin': '*',
            //         Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            //     } 
            // })
            // .then(res => {

            //     this.itemDetailLists = res.data.result
            //     this.tax = res.data.tax
            //     this.subTotal = res.data.subTotal
            //     this.total = res.data.total
            //     this.totalTerbilang = res.data.totalTerbilang
            //     this.totalWgtOrder = res.data.wgtOrder[0].wgt
            //     // if(this.totalWgtOrder){
            //     //     this.totalWgtOrder = res.data.wgtOrder[0].wgt
            //     // } else {
            //     //     this.totalWgtOrder = 0
            //     // }
            //     this.loadingDatatableDetail = false

            // })
            // .catch(err => {

            //     this.$store.dispatch('setOverlay', false)
            //     if(err.response.status == '401'){
            //         this.$router.push('/');
            //     }

            // })
        },

        getItemDetails(id){

            this.itemDetailLists = []
            this.loadingDatatableDetail = true

            axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList/getDataDetails?CustomerOrderNo=${id.trim()}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {

                this.loadingDatatableDetail = false
                this.itemDetailLists = res.data.result
                // this.tax = res.data.tax
                // this.subTotal = res.data.subTotal
                // this.total = res.data.total
                // this.totalTerbilang = res.data.totalTerbilang
                // this.totalWgtOrder = res.data.wgtOrder[0].wgt
                // if(this.totalWgtOrder){
                //     this.totalWgtOrder = res.data.wgtOrder[0].wgt
                // } else {
                //     this.totalWgtOrder = 0
                // }

            })
            .catch(err => {
                
                console.log(err);

            })

        },

        updateOfficeDropdown(id){
            if(id){
                axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList/getOfficeById?regionid=${id}`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {

                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList/getOfficeById?regionid=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.officeLists = res.data.office
                    this.disabled_office = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        updateSalesmanDropdown(id){
            if(id){

                axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList/getSalesmanById?officeid=${id}`, { 
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json', 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            } else {
                axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList/getSalesmanById?officeid=all`, { 
                    headers: { 
                        'Accept': 'application/json',
                        'Content-Type': 'application/json', 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                })
                .then(res => {
                    this.salesmanLists = res.data.sales
                    this.disabled_salesman = false
                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                        this.$router.push('/');
                    }

                })
            }
        },

        showImageModal(imgUrl){

            $('#imgModal').modal('show')
            this.imageUrl = process.env.VUE_APP_URL + imgUrl
            
        },

        async addItem(){

            // $('#addItemModal').modal('show')
            // console.log(this.addItemBookIdModel)
            this.dialogDetailItem = false
            await new Promise(resolve => setTimeout(resolve, 1000));
            this.dialogAddItem = true

            await axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList/getAddItemData`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.categoryLists = res.data.category
                this.machTypeLists = res.data.machType
                this.thicknessLists = res.data.thickness
                this.widthLists = res.data.width
                this.colorLists = res.data.color
                this.materialLists = res.data.material
                this.addIDLists = res.data.add_id
                this.torIDLists = res.data.tor_id
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async populateProdCode(){
            
            this.loadingProdCode = true
            this.prodIDLists = []
            this.prodCodeText = 'PRODUCT CODE'

            await axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList/getProdCode?brand=${this.brandModel?this.brandModel:''}&category=${this.categoryModel?this.categoryModel:''}&machType=${this.machTypeModel?this.machTypeModel:''}&width=${this.widthModel?this.widthModel:''}&thickness=${this.thicknessModel?this.thicknessModel:''}&material=${this.materialModel?this.materialModel:''}&color=${this.colorModel?this.colorModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.loadingProdCode = false
                this.prodIDLists = res.data.result
                this.prodCodeText = 'PRODUCT CODE (found ' + res.data.result.length + ' products)'
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async populateMachTypeByCategory(){

            await axios.get(`${process.env.VUE_APP_URL}/api/kri/OrderList/getMachTypeByCategory?category=${this.categoryModel?this.categoryModel:''}`, { 
                headers: { 
                    'Accept': 'application/json',
                    'Content-Type': 'application/json', 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.machTypeLists = res.data.result
                this.populateProdCode()
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async storeData(){

            if(this.brandModel == '' || this.brandModel == null){

                Swal.fire({
                    text: 'Please select Brand before add items',
                    icon: 'warning',
                })

                return false

            }

            if(this.categoryModel == '' || this.categoryModel == null){

                Swal.fire({
                    text: 'Please select Category before add items',
                    icon: 'warning',
                })

                return false

            }

            if(this.machTypeModel == '' || this.machTypeModel == null){

                Swal.fire({
                    text: 'Please select Category before add items',
                    icon: 'warning',
                })

                return false

            }

            if(this.priceModel == '' || this.priceModel == null){

                Swal.fire({
                    text: 'Please set item Price before add items',
                    icon: 'warning',
                })

                return false

            }

            if(this.quantityModel == '' || this.quantityModel == null){

                Swal.fire({
                    text: 'Please set Quantity before add items',
                    icon: 'warning',
                })

                return false

            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kri/OrderList/storeData`, { 

                    book_id : this.addItemBookIdModel ? this.addItemBookIdModel : '',
                    brand: this.brandModel ? this.brandModel : '',
                    category: this.categoryModel ? this.categoryModel : '',
                    machType: this.machTypeModel ? this.machTypeModel : '',
                    thickness: this.thicknessModel ? this.thicknessModel : '',
                    width: this.widthModel ? this.widthModel : '',
                    length: this.lengthModel ? this.lengthModel : '',
                    color: this.colorModel ? this.colorModel : '',
                    material: this.materialModel ? this.materialModel : '',
                    addID: this.addIDModel ? this.addIDModel : '',
                    torID: this.torIDModel ? this.torIDModel : '',
                    qty: this.quantityModel ? this.quantityModel : '',
                    price: this.priceModel ? this.priceModel : '',
                    prodID: this.prodIDModel ? this.prodIDModel : '',
                    priceMode: this.priceModeModel ? this.priceModeModel : '',
                    remarkItem: this.remarkItemModel ? this.remarkItemModel : ''

                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json', 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                this.brandModel = ''
                this.categoryModel = ''
                this.machTypeModel = ''
                this.thicknessModel = ''
                this.widthModel = ''
                this.lengthModel = ''
                this.colorModel = ''
                this.materialModel = ''
                this.addIDModel = ''
                this.torIDModel = ''
                this.quantityModel = ''
                this.priceModel = ''
                this.prodIDModel = ''
                this.remarkItemModel = ''
                this.prodIDLists = []

                this.itemDetailLists = res.data.result
                this.tax = res.data.tax
                this.subTotal = res.data.subTotal
                this.total = res.data.total
                this.totalTerbilang = res.data.totalTerbilang
                $('#addItemModal').modal('hide')

                Swal.fire({
                    text: 'Item added succesfully !',
                    icon: 'success',
                })

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        showDeleteHeader(item){
            this.order = item
            this.dialog_delete = true
        },

        showDeleteItem(item){
            this.order_item = item
            $('#detailDeleteItem').modal('show')
        },

        async deleteItem(){

            if(this.reason_id == '' || this.reason_id == null){

                Swal.fire({
                    text: 'Please select Reason before delete order',
                    icon: 'warning',
                })

                return false


            }

            $('#detailDeleteItem').modal('hide')
            
            this.itemDetailLists = []
            this.loadingDatatableDetail = true
            await axios.post(`${process.env.VUE_APP_URL}/api/kri/OrderList/deleteData`, { 
                    book_id : this.order_item.book_id,
                    item_num : this.order_item.item_num,
                    reason_id: this.reason_id ? this.reason_id : '',
                    remark: this.remark ? this.remark : ''
                },
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json', 
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {
                $('#detailDeleteItem').modal('hide')
                this.reason_id = ''
                this.remark = ''

                if(res.data.checkRemaining == 0){
                    this.itemDetailLists = []
                    // $('#detailModal').modal('hide')
                    this.dialogDetailItem = false
                    Swal.fire({
                        text: 'All Item Cancelled succesfully !',
                        icon: 'success',
                    })
                    this.getData()
                } else {
                    Swal.fire({
                        text: 'Item Cancelled successfully.',
                        icon: 'success',
                    })
                    this.getItemDetails(this.order_item.book_id)
                }
            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                    this.$router.push('/');
                }

            })
        },

        async deleteHeader(){
            if(this.reason_id == '' || this.reason_id == null){

                Swal.fire({
                    text: 'Please select Reason before delete order',
                    icon: 'warning',
                })

                return false

            
            }
            await axios.post(`${process.env.VUE_APP_URL}/api/kri/OrderList/deleteHeader`, { 

                book_id : this.order.book_id,
                reason_id: this.reason_id ? this.reason_id : '',
                remark: this.remark ? this.remark : ''

            },
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            }).then(res => {

                if(res.data.result == 'OK'){

                    this.dialog_delete = false
                    this.reason_id = ''
                    this.remark = ''

                    Swal.fire({
                        text: 'Order Cancelled successfully !',
                        icon: 'success',
                    })
                    this.getData()
                }

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                if(err.response.status == '401'){
                this.$router.push('/');
                }

            })

        },

        async sendToAdmin(){

            if(this.activeOrderId){

                axios.post(`${process.env.VUE_APP_URL}/api/kri/OrderList/sendToAdmin`, { 

                    id: this.activeOrderId ? this.activeOrderId : ''

                    },
                    {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                    }
                    )
                    .then(res => {

                        if(res.data.result == 'OK'){
                            // $('#detailModal').modal('hide')
                            this.dialogDetailItem = false
                            Swal.fire('Order Confirmed!', '', 'success')
                            this.getData()
                        }

                    })
                    .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    if(err.response.status == '401'){
                    this.$router.push('/');
                    }

                })

            }

        },

        async confirmOrder(){

            if(this.activeOrderId){

                axios.post(`${process.env.VUE_APP_URL}/api/kri/OrderList/confirmOrder`, { 

                id: this.activeOrderId ? this.activeOrderId : ''

                },
                {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
                }
                )
                .then(res => {

                    if(res.data.result == 'OK'){
                        // $('#detailModal').modal('hide')
                        this.dialogDetailItem = false
                        Swal.fire('Order Confirmed!', '', 'success')
                        this.getData()
                    }

                })
                .catch(err => {

                    this.$store.dispatch('setOverlay', false)
                    console.log(err)
                })               

            }

        },

        isNumber(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 48 || keyCode > 57)) { // 46 is dot
                event.preventDefault()
            }
        },

        isNumber2(event){
            let keyCode = (event.keyCode ? event.keyCode : event.which);
            if ((keyCode < 46 || keyCode > 57)) { // 46 is dot
                event.preventDefault()
            }
        },

        PrintSP(item){

            this.$router.push(`/printSP/${item.book_id}`);

        },

        terbilang(angka){

            var bilne=["","satu","dua","tiga","empat","lima","enam","tujuh","delapan","sembilan","sepuluh","sebelas"];

            if(angka < 12){

                return bilne[angka];

            }else if(angka < 20){

                return terbilang(angka-10)+" belas";

            }else if(angka < 100){

                return terbilang(Math.floor(parseInt(angka)/10))+" puluh "+terbilang(parseInt(angka)%10);

            }else if(angka < 200){

                return "seratus "+terbilang(parseInt(angka)-100);

            }else if(angka < 1000){

                return terbilang(Math.floor(parseInt(angka)/100))+" ratus "+terbilang(parseInt(angka)%100);

            }else if(angka < 2000){

                return "seribu "+terbilang(parseInt(angka)-1000);

            }else if(angka < 1000000){

                return terbilang(Math.floor(parseInt(angka)/1000))+" ribu "+terbilang(parseInt(angka)%1000);

            }else if(angka < 1000000000){

                return terbilang(Math.floor(parseInt(angka)/1000000))+" juta "+terbilang(parseInt(angka)%1000000);

            }else if(angka < 1000000000000){

                return terbilang(Math.floor(parseInt(angka)/1000000000))+" milyar "+terbilang(parseInt(angka)%1000000000);

            }else if(angka < 1000000000000000){

                return terbilang(Math.floor(parseInt(angka)/1000000000000))+" trilyun "+terbilang(parseInt(angka)%1000000000000);

            }

        },

        currentDate() {
            const current = new Date();
            // const date = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;      
            const date = current.toISOString().slice(0,10);
            return date;
        },

        editItem(item){

            console.log(item);

            this.editBookId = item.book_id.trim()
            this.item_num = item.item_num
            this.addIDModel = item.add_id
            this.torIDModel = item.tor_id
            this.priceEditModeModel = item.price_mode
            this.editLengthModel = parseFloat(item.panjang).toFixed(2)
            this.editQtyModel = item.lembar
            this.editPriceModel = parseFloat(item.set_price).toFixed(2)

            this.dialogEditItem = true

        },

        submitEditItem(){

            console.log(this.priceModeModel);

            if(this.editLengthModel == '' || this.editLengthModel == null){

                Swal.fire({
                    text: 'Please fill length !',
                    icon: 'warning',
                })

                return false

            }

            if(this.editQtyModel == '' || this.editQtyModel == null){

                Swal.fire({
                    text: 'Please fill Qty !',
                    icon: 'warning',
                })

                return false

            }

            if(this.editPriceModel == '' || this.editPriceModel == null || this.editPriceModel == 0){

                Swal.fire({
                    text: 'Please fill Price !',
                    icon: 'warning',
                })

                return false

            }

            this.$store.dispatch('setOverlay', true)

            axios.post(`${process.env.VUE_APP_URL}/api/kri/OrderList/submitEditOrder`, { 

                id: this.editBookId ? this.editBookId : '',
                item_num: this.item_num ? this.item_num : '',
                add_id: this.addIDModel ? this.addIDModel : '',
                tor_id: this.torIDModel ? this.torIDModel : '',
                length: this.editLengthModel ? this.editLengthModel : '',
                qty: this.editQtyModel ? this.editQtyModel : '',
                price: this.editPriceModel ? this.editPriceModel : '',
                priceMode: this.priceEditModeModel ? this.priceEditModeModel : '',

            },
            {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            }
            )
            .then(res => {

                this.$store.dispatch('setOverlay', false)
                if(res.data.result == 'OK'){
                    this.dialogEditItem = false
                    this.getItemDetails(this.editBookId)
                    Swal.fire('Order Successfully Changed !', '', 'success')
                }

            })
            .catch(err => {

                this.$store.dispatch('setOverlay', false)
                console.log(err)
            })               
            
        }


    }
    
}
</script>

<style scoped>

    .v-text-field {
        padding-top: 10px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1em;
        padding-bottom: 10px; 
        padding-left: 10px;
    }

    .cardCustom{
        padding-top: 10px;
    }

    .container{
        margin-bottom: 100px !important;
    }

    .v-text-field >>> input {
        font-size: 1em !important;
        font-weight: 100 !important;
        text-transform: capitalize !important;
    }
    .v-text-field >>> label {
        font-size: 0.8em !important;
    }
    .v-text-field >>> button {
        font-size: 0.8em !important;
    }

</style>